const APP_VERSION = "1.0.11";
const STORAGE_URL = "https://s3.amazonaws.com/downloads.walletcs.com/";

export const LINUX_X64_FILENAME = `${STORAGE_URL}walletcs-${APP_VERSION}-x86_64.AppImage`;
export const LINUX_X32_FILENAME = `${STORAGE_URL}walletcs-${APP_VERSION}-i386.AppImage`;
export const ARMV7_FILENAME = `${STORAGE_URL}walletcs-${APP_VERSION}-armv7l.AppImage`;

export const LINUX_X64_FILESIZE = "66.7";
export const LINUX_X32_FILESIZE = "73.5";
export const ARMV7_FILESIZE = "72.5";
