import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";

// temporary imports
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import downloadIcon from "../Assets/Images/download.svg";
import createAccountIcon from "../Assets/Images/create_account.svg";
import createTransactionIcon from "../Assets/Images/createTransation.svg";
import signTransactionIcon from "../Assets/Images/sign_transation.svg";
import broadcastTransactionIcon from "../Assets/Images/broadcast_transaction.svg";
import onlineStatus from "../Assets/Images/online_status.svg";
import offlineStatus from "../Assets/Images/offline_status.svg";

class Instructions extends Component {
  render() {
    return (
      <Fragment>
        {window.innerWidth >= 992 ? (
          <Controller>
            <Scene duration="400px" offset="200px">
              <Timeline>
                <Tween staggerTo={{ x: "-250px" }} stagger>
                  <MainWrapper className="target_container">
                    <StepWrapper key={0}>
                      <StepIcon src={downloadIcon} alt="WalletCS Step" />
                      <StepStatus src={onlineStatus} alt="WalletCS Status" />
                      <StepTitle>1. Download</StepTitle>
                      {window.innerWidth <= 992 ? (
                        <StepDescription>
                          Download and install WalletCS <br /> app on the
                          offline device.
                        </StepDescription>
                      ) : (
                        <StepDescription>
                          Download and install WalletCS app on the <br />{" "}
                          offline device.
                        </StepDescription>
                      )}
                      <SeeInstructions
                        href="https://github.com/walletcs/walletcs-app/blob/master/README.md#offline-computer-hardware-requirements"
                        target="__blank"
                      >
                        See instructions
                      </SeeInstructions>
                    </StepWrapper>

                    <StepWrapper
                      key={1}
                      style={{
                        marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
                      }}
                    >
                      <StepIcon src={createAccountIcon} alt="WalletCS Step" />
                      <StepStatus src={offlineStatus} alt="WalletCS Status" />
                      <StepTitle>2. Create an acccount</StepTitle>

                      {window.innerWidth <= 992 ? (
                        <StepDescription>
                          Create an account using <br /> WalletCS Offline App
                          and store <br />
                          private and public keys on the <br /> USB flash
                          drives. Keep your <br />
                          private keys offline!
                        </StepDescription>
                      ) : (
                        <StepDescription>
                          Create an account using WalletCS Offline <br /> App
                          and store private and public keys on <br /> the USB
                          flash drives. Keep your private <br /> keys offline!
                        </StepDescription>
                      )}
                    </StepWrapper>

                    <StepWrapper
                      key={2}
                      style={{
                        marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
                      }}
                    >
                      <StepIcon
                        src={createTransactionIcon}
                        alt="WalletCS Step"
                      />
                      <StepStatus src={onlineStatus} alt="WalletCS Status" />
                      <StepTitle>3. Create transaction</StepTitle>

                      {window.innerWidth <= 992 ? (
                        <StepDescription>
                          Fill the form on WalletCS web site <br /> to create an{" "}
                          <b>unsigned transaction</b> <br /> and save it to USB
                          drive.
                        </StepDescription>
                      ) : (
                        <StepDescription>
                          Fill the form on WalletCS web site to create <br /> an{" "}
                          <b>unsigned transaction</b> and save it to <br /> USB
                          drive.
                        </StepDescription>
                      )}
                    </StepWrapper>

                    <StepWrapper
                      key={3}
                      style={{
                        marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
                      }}
                    >
                      <StepIcon src={signTransactionIcon} alt="WalletCS Step" />
                      <StepStatus src={offlineStatus} alt="WalletCS Status" />
                      <StepTitle>4. Sign transaction</StepTitle>
                      {window.innerWidth <= 992 ? (
                        <StepDescription>
                          Use WalletCS Offline App to <br/>
                          sign the transaction and save it <br/>
                          back to the USB Drive
                        </StepDescription>
                      ) : (
                        <StepDescription>
                          Use WalletCS Offline App to <br/>
                          sign the transaction and save it <br/>
                          back to the USB Drive
                        </StepDescription>
                      )}
                    </StepWrapper>

                    <StepWrapper
                      key={4}
                      style={{
                        marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
                      }}
                    >
                      <StepIcon
                        src={broadcastTransactionIcon}
                        alt="WalletCS Step"
                      />
                      <StepStatus src={onlineStatus} alt="WalletCS Status" />
                      <StepTitle>5. Broadcast transaction</StepTitle>

                      {window.innerWidth <= 992 ? (
                        <StepDescription>
                          Upload signed transaction to the <br /> WalletCS Web
                          site and broadcast.
                        </StepDescription>
                      ) : (
                        <StepDescription>
                          Upload signed transaction to the WalletCS <br /> Web
                          site and broadcast.
                        </StepDescription>
                      )}
                    </StepWrapper>
                  </MainWrapper>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        ) : (
          <MainWrapper className="target_container">
            <StepWrapper key={0}>
              <StepIcon src={downloadIcon} alt="WalletCS Step" />
              <StepStatus src={onlineStatus} alt="WalletCS Status" />
              <StepTitle>1. Download</StepTitle>
              {window.innerWidth <= 992 ? (
                <StepDescription>
                  Download and install WalletCS <br /> app on the offline
                  device.
                </StepDescription>
              ) : (
                <StepDescription>
                  Download and install WalletCS app on the <br /> offline
                  device.
                </StepDescription>
              )}
              <SeeInstructions
                href="https://github.com/walletcs/walletcs-app/blob/master/README.md#offline-computer-hardware-requirements"
                target="__blank"
              >
                See instructions
              </SeeInstructions>
            </StepWrapper>

            <StepWrapper
              key={1}
              style={{
                marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
              }}
            >
              <StepIcon src={createAccountIcon} alt="WalletCS Step" />
              <StepStatus src={offlineStatus} alt="WalletCS Status" />
              <StepTitle>2. Create an acccount</StepTitle>

              {window.innerWidth <= 992 ? (
                <StepDescription>
                  Create an account using <br /> WalletCS Offline App and store{" "}
                  <br />
                  private and public keys on the <br /> USB flash drives. Keep
                  your <br />
                  private keys offline!
                </StepDescription>
              ) : (
                <StepDescription>
                  Create an account using WalletCS Offline <br /> App and store
                  private and public keys on <br /> the USB flash drives. Keep
                  your private <br /> keys offline!
                </StepDescription>
              )}
            </StepWrapper>

            <StepWrapper
              key={2}
              style={{
                marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
              }}
            >
              <StepIcon src={createTransactionIcon} alt="WalletCS Step" />
              <StepStatus src={onlineStatus} alt="WalletCS Status" />
              <StepTitle>3. Create transaction</StepTitle>

              {window.innerWidth <= 992 ? (
                <StepDescription>
                  Fill the form on WalletCS web site <br /> to create an{" "}
                  <b>unsigned transaction</b> <br /> and save it to USB drive.
                </StepDescription>
              ) : (
                <StepDescription>
                  Fill the form on WalletCS web site to create <br /> an{" "}
                  <b>unsigned transaction</b> and save it to <br /> USB drive.
                </StepDescription>
              )}
            </StepWrapper>

            <StepWrapper
              key={3}
              style={{
                marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
              }}
            >
              <StepIcon src={signTransactionIcon} alt="WalletCS Step" />
              <StepStatus src={offlineStatus} alt="WalletCS Status" />
              <StepTitle>4. Sign transaction</StepTitle>
              {window.innerWidth <= 992 ? (
                <StepDescription>
                  Use WalletCS Offline App to <br/>
                  sign the transaction and save it <br/>
                  back to the USB Drive
                </StepDescription>
              ) : (
                <StepDescription>
                  Use WalletCS Offline App to <br/>
                  sign the transaction and save it <br/>
                  back to the USB Drive
                </StepDescription>
              )}
            </StepWrapper>

            <StepWrapper
              key={4}
              style={{
                marginLeft: window.innerWidth <= 992 ? "72px" : "100px"
              }}
            >
              <StepIcon src={broadcastTransactionIcon} alt="WalletCS Step" />
              <StepStatus src={onlineStatus} alt="WalletCS Status" />
              <StepTitle>5. Broadcast transaction</StepTitle>

              {window.innerWidth <= 992 ? (
                <StepDescription>
                  Upload signed transaction to the <br /> WalletCS Web site and
                  broadcast.
                </StepDescription>
              ) : (
                <StepDescription>
                  Upload signed transaction to the WalletCS <br /> Web site and
                  broadcast.
                </StepDescription>
              )}
            </StepWrapper>
          </MainWrapper>
        )}
      </Fragment>
    );
  }
}

export default Instructions;

// Styled Components
const MainWrapper = styled.ul`
  scrollbar-width: none;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  display: flex;
  align-items: center;
  width: 400px;
  padding-left: 5px;
  margin-top: 200px;

  /* 
    ## Device = Tablets, Ipads (portrait)
    ## Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 800px;
    padding-left: 50px;
  }

  /* 
    ## Device = Tablets, Ipads (landscape)
    ## Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 80px;
    width: 1000px;
    padding-left: 40px;
    margin-bottom: 180px;
  }

  /* 
    ## Device = Desktops
    ## Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1281px) {
    width: 2300px;
    transform: translateX(590px);
    margin-bottom: -1100px;
  }

  /* 
    ## Device = Laptops, Desktops
    ## Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 3000px;
    padding-left: 1030px;
  }

  @media (max-width: 992px) {
    padding-left: 80px;
    width: 850px !important;
  }

  @media (max-width: 850px) {
    padding-left: 55px;
    width: 800px !important;
  }

  @media screen and (max-width: 834px) {
    width: 780px;
    margin-left: -20px;
  }

  @media (max-width: 790px) {
    padding-left: 15px;
    width: 700px !important;
  }

  /* 
    ## Device = Low Resolution Tablets, Mobiles (Landscape)
    ## Screen = B/w 481px to 767px
  */
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: -22px !important;
    padding-left: 120px;
    width: 750px !important;
  }

  @media (max-width: 735px) {
    padding-left: 115px;
    width: 740px !important;
  }

  @media (max-width: 720px) {
    padding-left: 100px;
    width: 700px !important;
  }

  @media (max-width: 682px) {
    padding-left: 100px;
    width: 680px !important;
  }

  @media (max-width: 670px) {
    padding-left: 90px;
    width: 660px !important;
  }

  @media (max-width: 650px) {
    padding-left: 80px;
    width: 650px !important;
  }

  @media (max-width: 630px) {
    padding-left: 70px;
    width: 630px !important;
  }

  @media (max-width: 620px) {
    padding-left: 50px;
    width: 610px !important;
  }

  @media (max-width: 600px) {
    padding-left: 40px;
    width: 600px !important;
  }

  @media (max-width: 580px) {
    padding-left: 30px;
    width: 570px !important;
  }

  @media (max-width: 569px) {
    padding-left: 30px;
    width: 570px !important;
  }

  @media (max-width: 550px) {
    padding-left: 20px;
    width: 530px !important;
  }

  @media (max-width: 500px) {
    padding-left: 20px;
    width: 490px !important;
  }

  @media (max-width: 479px) {
    padding-left: 20px;
    width: 470px !important;
    margin-top: -350px;
  }

  @media (max-width: 450px) {
    padding-left: 25px;
    width: 440px !important;
    margin-top: -350px;
  }

  @media (max-width: 430px) {
    padding-left: 25px;
    width: 400px !important;
  }

  @media screen and (max-width: 415px) {
    width: 380px;
  }

  @media screen and (max-width: 380px) {
    width: 370px !important;
  }

  @media screen and (max-width: 360px) {
    width: 360px !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StepWrapper = styled.div`
  width: 348px;
  height: 338px;
  display: inline-block;

  @media screen and (max-width: 992px) {
    width: 228px;
  }
`;

const StepIcon = styled.img`
  margin-bottom: 34px;
  height: 70px;
`;

const StepStatus = styled.img`
  display: block;
  margin-bottom: 6px;
  width: 76px;
  height: 24px;
`;

const StepTitle = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 14px;
`;

const StepDescription = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const SeeInstructions = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #26cd58 !important;
  position: relative;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg, #027722 0%, #26cd58 0.01%);
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    bottom: -6px;
  }

  &:hover {
    color: white !important;
  }

  &:active:before {
    background: white;
  }
`;
