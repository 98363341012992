import React, { Fragment, useState } from "react";
import styled from "styled-components";

import {
  Header,
  Footer,
  Description,
  Why,
  How,
  About,
  Contact,
  Social
} from "../Components";
import bgImage from "../Assets/Images/particles@2x.svg";

const MainPage = ({ history }) => {
  const [on, toggle] = useState(false);

  return (
    <Fragment>
      <Header on={on} toggle={toggle} history={history} />
      <Description />
      <Why />
      <How />
      <About />
      <Contact />

      {window.innerWidth >= 769 ? null : <Social />}
    </Fragment>
  );
};

const Background = styled.div`
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-position: center 300px;
  background-attachment: fixed;
  position: absolute;
  top: 0px;
  width: 98vw;
  height: 100vh;
  z-index: -2;
`;

const BackgroundImage = styled.div`
  z-index: -3;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: 1000px;
  background-repeat: no-repeat;
  background-position: 70% 98%;
  position: absolute;
  top: 0;
  width: 98vw;
  height: 100%;
`;

export default MainPage;
