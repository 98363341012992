import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";

import withIntersectionObserver from "../Utils/withintersectionObserver";
import Instructions from "./Instructions";

const How = ({ isVisible, on }) => {
  return (
    <Fragment>
      <MainWrapper className="target_wrapper">
        <Container>
          <div
            style={{
              marginTop: window.innerWidth >= 769 ? "180px" : "60px",
              marginBottom: window.innerWidth >= 769 ? "120px" : "60px"
            }}
          >
            <Row>
              <Col>
                <Title>How it works</Title>
                <DocumentationText>
                  Check our{" "}
                  <DocumentationLink
                    href="https://github.com/walletcs/walletcs-app/blob/master/README.md#walletcs"
                    target="__blank"
                  >
                    documentation
                  </DocumentationLink>{" "}
                  for more details.
                </DocumentationText>
              </Col>
            </Row>
          </div>
        </Container>

        <Row className="instructionsRow">
          <Instructions />
        </Row>
      </MainWrapper>
    </Fragment>
  );
};

export default How;
// export default withIntersectionObserver(0.66)(How);

// Styled Components
const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .instructionsRow {
    @media screen and (max-width: 1990px) {
      margin-top: -200px;
    }

    @media screen and (max-width: 1280px) {
      margin-top: 350px;
      margin-left: -200px;
    }

    @media screen and (max-width: 1196px) {
      margin-left: -10px;
    }

    @media screen and (max-width: 1025px) {
      margin-left: 0px;
      margin-top: -100px;
    }

    @media screen and (max-width: 992px) {
      margin-top: -90px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 80px;
    }

    @media screen and (max-width: 479px) {
      margin-top: 350px;
    }
  }

  /* Screens before 992px */
  @media screen and (min-width: 992px) {
    height: 100vh;
    margin-bottom: 80px;
  }

  /* NOTE
    use this media query in order to work with the screens  
    of width less than 920px
  */

  /* Screens after 992px */
  /* @media screen and (max-width: 992px) {
  } */

  /* Screens before 1930px */
  @media (min-width: 1930px) {
  }

  /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1281px) {
  }

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    padding-top: 0px;
  }
`;

const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  color: #ffffff;
  display: block;
  margin-left: -8px;

  @media screen and (max-width: 1200px) {
    font-size: 44px;
    line-height: 56px;
  }

  @media screen and (max-width: 992px) {
    font-size: 36px !important;
    line-height: 44px;
    margin-top: 30px;
    margin-left: -5.5px;
  }
`;

const DocumentationText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-left: -5px;
  display: inline-block;

  @media screen and (min-width: 992px) {
    margin-top: 10px;
  }
`;

const DocumentationLink = styled.a`
  cursor: pointer;
  text-decoration: none !important;
  position: relative;
  color: #26cd58 !important;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 3;

  &:hover {
    color: white !important;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -6px;
    height: 4px;
    width: 100%;
    background: linear-gradient(270deg, #027722 0%, #26cd58 0.01%);
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:active::before {
    background: white;
  }
`;
