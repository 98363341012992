import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Waypoint } from "react-waypoint";

import Social from "./Social";

const Contact = props => {
  // hooks
  const [spotted, setSpotted] = useState(false);

  // // animations
  // const textAnimation = useSpring({
  //   opacity: spotted ? 1 : 0
  // });

  return (
    <Container>
      <Row>
        {window.innerWidth >= 992 ? (
          <Fragment>
            <Waypoint onEnter={_ => setSpotted(true)} bottomOffset="5%" />
            <Col xl={5} lg={5} md={5}>
              <span className={spotted ? "tile tile-1" : "tile tileOut-1"}>
                <Title>Contact us</Title>
              </span>
            </Col>
            <Col md={7} sm={6}>
              <Description>
                <span className={spotted ? "tile tile-2" : "tile tileOut-2"}>
                  Need help to customize WalletCS to work
                </span>
                <span className={spotted ? "tile tile-3" : "tile tileOut-3"}>
                  with your custom smart contracts?
                </span>
              </Description>
              <Email href="mailto:sales@exilion.com">sales@exilion.com</Email>
              <br />
              <Social />
            </Col>
          </Fragment>
        ) : (
          <Col>
            <Title>Contact us</Title>
            <Description>
              Need help to customize WalletCS to work with your custom smart
              contracts?
            </Description>
            <Email href="mailto:sales@exilion.com">sales@exilion.com</Email>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Contact;

// Styled Components
const Title = styled(animated.h1)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  color: #ffffff;
  margin-bottom: 20px;

  @media screen and (max-width: 769px) {
    font-size: 24px !important;
    line-height: 32px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 44px;
    line-height: 56px;
  }

  @media screen and (max-width: 992px) {
    font-size: 36px !important;
    line-height: 44px;
    margin-bottom: 20px;
  }
`;

const Description = styled(animated.p)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;
  margin-bottom: 50px !important;

  @media screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (max-width: 769px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Email = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #26cd58;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &::before {
    content: "";
    position: absolute;
    bottom: -20px;
    height: 4px;
    width: 100%;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    background: linear-gradient(270deg, #027722 0%, #26cd58 0.01%);
  }

  &:hover {
    color: white;
    text-decoration: none;
  }

  &:hover::before {
    background: linear-gradient(270deg, #027722 0%, #26cd58 0.01%);
  }

  &:active::before {
    background: white;
  }

  @media screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (max-width: 769px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
