import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "react-spring";

const About = props => {
  // hooks
  const [spotted, setSpotted] = useState(false); // text spotted state

  // animations
  const { opacity, ...textAnimation } = useSpring({
    opacity: spotted ? 1 : 0
  });

  return (
    <Wrapper>
      <Element name="about" className="about">
        <Container
          style={{
            marginBottom: window.innerWidth >= 992 ? "180px" : "60px"
          }}
        >
          <Row>
            {window.innerWidth >= 992 ? (
              <Fragment>
                <Waypoint
                  onEnter={_ => setSpotted(true)}
                  onLeave={_ => setSpotted(false)}
                  bottomOffset="30%"
                />
                <Col xl={5} lg={5} md={5}>
                  <span className={spotted ? "tile tile-1" : "tile tileOut-1"}>
                    <Title>About</Title>
                  </span>
                </Col>
                <Col>
                  <Description>
                    <span
                      className={spotted ? "tile tile-2" : "tile tileOut-2"}
                    >
                      We have started WalletCS project because we believe that
                      secure
                    </span>
                    <span
                      className={spotted ? "tile tile-3" : "tile tileOut-3"}
                    >
                      blockchain environment will greatly promote entire
                      blockchain ecosystem.
                    </span>
                    <span
                      className={spotted ? "tile tile-4" : "tile tileOut-4"}
                    >
                      WalletCS is an open source software free
                    </span>
                    <span
                      className={spotted ? "tile tile-5" : "tile tileOut-5"}
                    >
                      to the community.
                    </span>
                  </Description>
                </Col>
              </Fragment>
            ) : (
              <Col>
                <Waypoint onEnter={_ => setSpotted(true)} bottomOffset="20%" />
                <Title style={textAnimation}>About</Title>
                <Description style={textAnimation}>
                  We have started WalletCS project because we believe that
                  secure blockchain environment will greatly promote entire
                  blockchain ecosystem. WalletCS is an open source software free
                  to the community.
                </Description>
              </Col>
            )}
          </Row>
        </Container>
      </Element>
    </Wrapper>
  );
};

export default About;

// Styled Components
const Wrapper = styled.div`
  @media screen and (min-width: 992px) {
  }
`;

const Title = styled(animated.h1)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 20px;
  color: #ffffff;

  @media screen and (max-width: 1200px) {
    font-size: 44px;
    line-height: 56px;
  }

  @media screen and (max-width: 992px) {
    font-size: 36px !important;
    line-height: 44px;
    margin-bottom: 20px;
  }
`;

const Description = styled(animated.p)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;

  @media screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (max-width: 769px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
