import React, { Fragment } from "react";
import styled from "styled-components";

export default ({
  icon,
  os,
  size,
  link,
  hasIcon = true,
  color = "#010D17"
}) => (
  <Wrapper>
    {hasIcon ? (
      <Fragment>
        <Icon src={icon} alt="WalletCS Download" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <OSName href={link} color={color}>
            <span
              style={{
                fontFamily: "IBM Plex Sans",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "16px",
                color,
                display: "block",
                transition: "all 300ms cubic-bezier(0.075, 0.82, 0.165, 1)"
              }}
            >
              Download for
            </span>
            {os}
          </OSName>
          <Size>{size} Mb</Size>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "46px"
          }}
        >
          <OSName color={color} href={link}>
            {os}
          </OSName>
          <Size>{size} Mb</Size>
        </div>
      </Fragment>
    )}
  </Wrapper>
);

// Styled Components
const MainWrapper = styled.a`
  color: #010d17;
  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 7px;
`;

const Icon = styled.img`
  margin-right: 16px;
  margin-top: 6px;
`;

const OSName = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: ${({ color }) => color};
  padding: 0;
  margin: 0;
  width: 133px;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;

  &:hover {
    color: #05c44c !important;
    text-decoration: none;

    span {
      color: #05c44c !important;
    }
  }

  &:active {
    color: #039138 !important;
    text-decoration: none;

    span {
      color: #039138 !important;
    }
  }
`;

const Size = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #6e7782;
`;
