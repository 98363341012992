import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Container, Nav } from "react-bootstrap";
import { Collapse } from "reactstrap";

import closeMenu from "../Assets/Images/closeMenu.svg";
import githubIcon from "../Assets/Images/github.svg";
import redditIcon from "../Assets/Images/reddit.svg";
import twitterIcon from "../Assets/Images/twitter.svg";
import downArrow from "../Assets/Images/downloads_arrow.svg";
import downArrowMobile from "../Assets/Images/down_arrow_mobile.svg";
import DownloadsMobile from "./Downloads/DownloadsMobile/DownloadsMobile";

export default class Navigation extends Component {
  state = { collapse: false };

  collapseToggle = _ =>
    this.setState(({ collapse }) => ({ collapse: !collapse }));

  render() {
    const { type, toggle } = this.props;

    switch (type) {
      case "mobile" || "Mobile":
        return (
          <Container>
            <MobileWrapper>
              <Row>
                <Col>
                  <Container style={{ textAlign: "right" }}>
                    <CloseIcon
                      onClick={_ => toggle(false)}
                      src={closeMenu}
                      alt="WalletCS Close"
                    />
                  </Container>
                </Col>
              </Row>

              <Nav style={{ marginTop: "63px" }} className="flex-column">
                <Link className="text-center">Products</Link>
                <Link className="text-center" onClick={this.collapseToggle}>
                  Downloads
                  <img
                    src={downArrowMobile}
                    alt="WalletCS"
                    style={{ marginLeft: "10px" }}
                  />
                </Link>

                <DownloadsMobile collapse={this.state.collapse} />
                <Link
                  className="text-center"
                  href="https://github.com/walletcs/walletcs-app/blob/master/README.md#getting-support"
                  target="__blank"
                >
                  Support
                </Link>
                <Link className="text-center">About</Link>
              </Nav>

              <Row>
                <Col
                  style={{
                    marginTop: "33px",
                    textAlign: "center"
                  }}
                >
                  <CreateTransaction
                    href="https://app.walletcs.com"
                    target="__blank"
                  >
                    Create Transaction
                  </CreateTransaction>
                </Col>
              </Row>

              <Row
                style={{
                  textAlign: "center",
                  width: "160px",
                  margin: "64px auto"
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <SocialLink
                    target="__blank"
                    href="https://github.com/walletcs/walletcs-app"
                  >
                    <SocialIcon src={githubIcon} alt="Github" />
                  </SocialLink>

                  <SocialLink href="/">
                    <SocialIcon src={redditIcon} alt="Reddit" />
                  </SocialLink>

                  <SocialLink href="/">
                    <SocialIcon src={twitterIcon} alt="Twitter" />
                  </SocialLink>
                </Col>
              </Row>

              <Row>
                <Col style={{ textAlign: "center" }}>
                  <TermsOfUse
                    href="https://github.com/walletcs/walletcs-app/blob/master/LICENSE"
                    target="__blank"
                  >
                    Terms of use
                  </TermsOfUse>
                </Col>
              </Row>
            </MobileWrapper>
          </Container>
        );

      case "desktop" || "Desktop":
        return (
          <Wrapper>
            <h1>Desktop Navigation</h1>
          </Wrapper>
        );

      default:
        return;
    }
  }
}

// Styled Components
const Wrapper = styled.div``;

const MobileWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  background: #010d17;
`;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-top: 20px;
`;

const Link = styled.a`
  text-align: right;
  display: block;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 32px;
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
  z-index: 2;
  position: relative;
  outline: 0;

  &:hover {
    color: white !important;
    text-decoration: none;
  }
`;

// const DownloadButton = styled.a`
//   background: rgba(38, 205, 88, 0.241423);
//   border: 1px solid #04aa42;
//   box-sizing: border-box;
//   border-radius: 2px;
//   padding: 13px 16px;
//   cursor: pointer;
//   font-family: "IBM Plex Sans";
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   margin-right: 16px;
//   transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

//   &:hover {
//     background: #05c44c;
//     border-radius: 4px;
//   }

//   &:active {
//     background: #039138;
//     border-radius: 4px;
//   }
// `;

const CreateTransaction = styled.a`
  background: #04aa42;
  border-radius: 2px;
  padding: 13px 16px;
  cursor: pointer;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #ffffff;

  &:hover {
    background: #05c44c;
    border-radius: 4px;
    text-decoration: none;
    color: #ffffff;
  }

  &:active {
    background: #039138;
    border-radius: 4px;
  }
`;

const SocialLink = styled.a``;

const SocialIcon = styled.img``;

const TermsOfUse = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  text-align: center;
`;
