import React, { Fragment, useState } from "react";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";
import Portal from "../Utils/Portal";
import useMeasure from "../Utils/useMeasure";

import ScrollToTop from "./ScrollToTop";

const Layout = ({ children, ...props }) => {
  const [on, toggle] = useState(false);
  // const [bind, measure] = useMeasure();

  return (
    <Fragment>
      <ScrollToTop />
      {/* <Header toggle={toggle} on={on} /> */}
      {children}
      <Footer />
    </Fragment>
  );
};

export default Layout;
