import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Collapse } from "reactstrap";
import { Waypoint } from "react-waypoint";

import { Icon, Header } from "../../Components";
import {
  LINUX_X32_FILENAME,
  LINUX_X32_FILESIZE,
  LINUX_X64_FILENAME,
  LINUX_X64_FILESIZE,
  ARMV7_FILESIZE,
  ARMV7_FILENAME
} from "../../Utils/constants";
import "./Downloads.scss";

const Downloads = ({ on, toggle, history }) => {
  const [collapsedWindows, setCollapsedWindows] = useState(false); // collapse state for Windows
  const [collapsedApple, setCollapsedApple] = useState(false); // collapse state for macos
  const [collapsedLinux, setCollapsedLinux] = useState(false); // collapse state for linux
  const [textSpotted, setTextSpotted] = useState(false); // information text state

  return (
    <Fragment>
      <Header on={on} toggle={toggle} history={history} />
      <Wrapper>
        <Container>
          <Title>Downloads</Title>

          {window.innerWidth >= 992 ? (
            <Fragment>
              {/* WINDOWS */}
              <DownloadRow>
                <OS>
                  <Icon type="windows" />
                  <OSName>Windows</OSName>
                </OS>

                <Download href="" style={{ width: "184px" }}>
                  <Icon type="download" />

                  <DownloadText>
                    <DownloadName>WalletCS_win.exe</DownloadName>
                    <DownloadSize>73.3 mb</DownloadSize>
                  </DownloadText>
                </Download>

                <Signature>
                  <span style={{ marginRight: "8px" }}>Signature</span>
                  <Icon type="signature" />
                </Signature>
              </DownloadRow>

              <HR />

              {/* MACOS */}
              <DownloadRow>
                <OS>
                  <Icon type="apple" />
                  <OSName>MacOS</OSName>
                </OS>

                <Download href="">
                  <Icon type="download" />

                  <DownloadText>
                    <DownloadName>WalletCS_MacOs.dmg</DownloadName>
                    <DownloadSize>56.3 mb</DownloadSize>
                  </DownloadText>
                </Download>

                <Signature>
                  <span style={{ marginRight: "8px" }}>Signature</span>
                  <Icon type="signature" />
                </Signature>
              </DownloadRow>

              <HR />

              {/* LINUX */}
              <DownloadRow
                style={{ gridTemplateColumns: "30% auto", alignItems: "start" }}
              >
                <OS>
                  <Icon type="linux" />
                  <OSName>Linux</OSName>
                </OS>

                <DownloadList>
                  {/* 64 BIT */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Download
                      href={LINUX_X64_FILENAME}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "184px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon type="download" />

                        <DownloadText>
                          <DownloadName>WalletCS_64bit.tar.gz</DownloadName>
                          <DownloadSize>{LINUX_X64_FILESIZE} mb</DownloadSize>
                        </DownloadText>
                      </div>
                    </Download>
                    <Signature
                      href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-x86_64.AppImage.asc"
                      target="__blank"
                    >
                      <span style={{ marginRight: "8px" }}>Signature</span>
                      <Icon type="signature" />
                    </Signature>
                  </div>

                  <HRShort />

                  {/* 32 BIT */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Download
                      href={LINUX_X32_FILENAME}
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon type="download" />

                        <DownloadText>
                          <DownloadName>WalletCS_32bit.tar.gz</DownloadName>
                          <DownloadSize>{LINUX_X32_FILESIZE} mb</DownloadSize>
                        </DownloadText>
                      </div>
                    </Download>
                    <Signature
                      href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-i386.AppImage.asc"
                      target="__blank"
                    >
                      <span style={{ marginRight: "8px" }}>Signature</span>
                      <Icon type="signature" />
                    </Signature>
                  </div>

                  <HRShort />

                  {/* ARMV7 */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Download
                      href={ARMV7_FILENAME}
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon type="download" />

                        <DownloadText>
                          <DownloadName>WalletCS_ARM.tar.gz</DownloadName>
                          <DownloadSize>{ARMV7_FILESIZE} mb</DownloadSize>
                        </DownloadText>
                      </div>
                    </Download>
                    <Signature
                      href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-armv7l.AppImage.asc"
                      target="__blank"
                    >
                      <span style={{ marginRight: "8px" }}>Signature</span>
                      <Icon type="signature" />
                    </Signature>
                  </div>
                </DownloadList>
              </DownloadRow>
            </Fragment>
          ) : (
            <Fragment>
              {/* WINDOWS MOBILE */}
              <MobileDownload
                onClick={_ => setCollapsedWindows(!collapsedWindows)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon type="windows" />
                  <MobileTitle>Windows</MobileTitle>
                  <Icon type="downArrow" />
                </div>

                <Collapse isOpen={collapsedWindows}>
                  <MobileWrapper>
                    <MobileInner>
                      <Icon type="download" />

                      <MobileText>
                        <h1>WalletCS_win.exe</h1>
                        <p>73.3 mb</p>
                      </MobileText>
                    </MobileInner>

                    <MobileSignature>
                      <span>Signature</span>
                      <Icon type="signature" isMobile />
                    </MobileSignature>
                  </MobileWrapper>
                </Collapse>
              </MobileDownload>

              {/* MACOS MOBILE */}
              <MobileDownload onClick={_ => setCollapsedApple(!collapsedApple)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon type="apple" />
                  <MobileTitle>MacOS</MobileTitle>
                  <Icon type="downArrow" />
                </div>

                <Collapse isOpen={collapsedApple}>
                  <MobileWrapper>
                    <MobileInner>
                      <Icon type="download" />

                      <MobileText>
                        <h1>WalletCS_MacOs.dmg</h1>
                        <p>73.3 mb</p>
                      </MobileText>
                    </MobileInner>

                    <MobileSignature>
                      <span>Signature</span>
                      <Icon type="signature" isMobile />
                    </MobileSignature>
                  </MobileWrapper>
                </Collapse>
              </MobileDownload>

              {/* LINUX MOBILE */}
              <MobileDownload onClick={_ => setCollapsedLinux(!collapsedLinux)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon type="linux" />
                  <MobileTitle>Linux</MobileTitle>
                  <Icon type="downArrow" />
                </div>

                <Collapse isOpen={collapsedLinux}>
                  <MobileWrapper href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-x86_64.AppImage">
                    <MobileInner>
                      <Icon type="download" />

                      <MobileText>
                        <h1>WalletCS_64bit.tar.gz</h1>
                        <p>66.7 mb</p>
                      </MobileText>
                    </MobileInner>

                    <MobileSignature
                      href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-x86_64.AppImage.asc"
                      target="__blank"
                    >
                      <span>Signature</span>
                      <Icon type="signature" isMobile />
                    </MobileSignature>
                  </MobileWrapper>

                  <MobileWrapper href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-i386.AppImage">
                    <MobileInner>
                      <Icon type="download" />

                      <MobileText>
                        <h1>WalletCS_32bit.tar.gz</h1>
                        <p>73.5 mb</p>
                      </MobileText>
                    </MobileInner>

                    <MobileSignature
                      href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-i386.AppImage.asc"
                      target="__blank"
                    >
                      <span>Signature</span>
                      <Icon type="signature" isMobile />
                    </MobileSignature>
                  </MobileWrapper>

                  <MobileWrapper href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-armv7l.AppImage">
                    <MobileInner>
                      <Icon type="download" />

                      <MobileText>
                        <h1>WalletCS_ARM.tar.gz</h1>
                        <p>72.5 mb</p>
                      </MobileText>
                    </MobileInner>

                    <MobileSignature
                      href="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-armv7l.AppImage.asc"
                      target="__blank"
                    >
                      <span>Signature</span>
                      <Icon type="signature" isMobile />
                    </MobileSignature>
                  </MobileWrapper>
                </Collapse>
              </MobileDownload>
            </Fragment>
          )}

          <Waypoint onEnter={ () => setTextSpotted(true)} bottomOffset="50%" />
          <Information>
            <Title className={textSpotted ? "tile tile-1" : "tile tileOut-1"}>
              How to verify{" "}
              <span style={{ color: "#26CD58" }}>GPG signatures</span>
            </Title>

            <Text>
              <span className={textSpotted ? "tile tile-2 " : "tile tileOut-2"}>
                GPG signatures are used to prove that files have not been
                modified.
              </span>
              <span className={textSpotted ? "tile tile-3" : "tile tileOut-3"}>
                If this web site was hacked and original files replaced by the
                attacker, signature verification would fail.
              </span>
              <br />
              <br />
              <ol style={{ paddingLeft: "10px" }}>
                <li>
                  <span
                    className={textSpotted ? "tile tile-4" : "tile tileOut-4"}
                  >
                    Download
                  </span>
                  <ul>
                    <li>
                      walletcs public key{" "}
                      <a
                        href="https://raw.githubusercontent.com/walletcs/walletcs-app/master/pubkeys/walletcs.asc"
                        target="__blank"
                      >
                        walletcs_public.asc
                      </a>
                    </li>
                    <li>package signature, e.g. walletcs_xx.zip.asc</li>
                    <li>package walletcs_xx.zip</li>
                  </ul>
                </li>
                <br />
                <br />
                <li>
                  <span
                    className={textSpotted ? "tile tile-4" : "tile tileOut-4"}
                  >
                    Install GPG software
                  </span>
                </li>
                 Windows: <a href="https://www.gpg4win.org/">Install Gpg4win</a>
                <br />
                Mac OS: <a href="https://gpgtools.org/">GPGtools</a>
                <br />
                Linux: <a href="https://gnupg.org/download/index.html">gnupg.org (already pre-installed on Ubuntu)</a>
                <br />
                <br />
                <br />
                <li>
                  <span
                    className={textSpotted ? "tile tile-4" : "tile tileOut-4"}
                  >
                    Import with gpg
                  </span>
                </li>
                <CommandText>
                  gpg --import walletcs_public.asc
                </CommandText>
                <br />
                <br />
                <li>
                  <span
                    className={textSpotted ? "tile tile-4" : "tile tileOut-4"}
                  >
                    Verify downloaded file
                  </span>
                </li>
                <CommandText>
                  gpg --verify walletcs_xx.zip.asc walletcs_xx.zip
                </CommandText>
              </ol>
              <br />
              <br />
              In verification result look for a line:
              <br />
              <CommandText>
              <span>
                  gpg: Signature made 07/20/19 14:01:26 Eastern Daylight Time
              </span>
              <br />
                <span>
                  gpg: using RSA key{" "}
                  {window.innerWidth >= 992 ? (
                    "6C574B2F5F1159E25BCBBC8405F35601173A51AE"
                  ) : (
                    <span style={{ fontSize: "20px", wordBreak: 'break-all' }}>
                      6C574B2F5F1159E25BCBBC8405F35601173A51AE
                    </span>
                  )}
                </span>
                <br />
                <span>gpg: Good signature from "WalletCS"</span>
              </CommandText>
              <br />
              <br />
              <span>
                Ensure RSA key id matches WalletCS Public Key fingerprint:{" "}
              </span>
              <br />
              <span>6C57 4B2F 5F11 59E2 5BCB BC84 05F3 5601 173A 51AE</span>
              <br />
              <br />
              <br />
              You may receive a certification warning like this:
              <br />
              <CommandText>
                <span>
                  gpg: WARNING: This key is not certified with a trusted
                  signature!
                </span>
                <br />
                <span>
                  gpg: There is no indication that the signature belongs to the
                  owner.
                </span>
              </CommandText>
              <br />
              <br />
              This means that you have not certified the public key. You can
              <br />
              either ignore it or certify the public key on your system.
            </Text>
          </Information>
        </Container>
      </Wrapper>
    </Fragment>
  );
};

// styled components
const Text = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 44px;
  color: #ffffff;

  @media screen and (max-width: 992px) {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  }
`;

const Information = styled.div`
  margin-top: 124px;
`;

const List = styled.ul``;

const Wrapper = styled.div`
  margin-top: 96px;
`;

const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 72px;
  color: #ffffff;
  margin-bottom: 64px;

  @media screen and (max-width: 992px) {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
  }
`;

const DownloadRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 6fr auto;
  align-items: center;

  @media screen and (max-width: 992px) {
    /* grid-template-columns: ; */
  }
`;

const OS = styled.div`
  display: flex;
  align-items: center;
  width: 153px;
`;

const OSName = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-left: 12px;
  color: #ffffff;
`;

const HR = styled.hr`
  background: #202d3d;
  margin: 23px 0px;
`;

const HRShort = styled.hr`
  background: #202d3d;
  margin: 23px 0px;
`;

// Download section
const Download = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:hover p {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    color: #04aa42;
  }

  &:hover .downloadIcon {
    fill: white;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover .downloadIconArrow {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    stroke: #26cd58;
  }
`;

// a list of downloads
// figma project for more
const DownloadList = styled.ul``;

const DownloadText = styled.div`
  margin-left: 16px;
`;

const DownloadName = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
`;

const DownloadSize = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #04aa42;
  margin: 0;
`;

const Signature = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff !important;
  cursor: pointer;
  display: block;

  span,
  .signatureIcon {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover span {
    color: #04aa42;
  }

  &:hover .signatureIcon {
    fill: #04aa42;
  }
`;

// styled components for mobile version
const MobileDownload = styled.div`
  margin-top: 60px;
`;

const MobileTitle = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  margin-left: 12px;
  margin-right: 10px;
`;

const MobileWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

const MobileInner = styled.div`
  display: flex;
  align-items: center;
`;

const MobileText = styled.div`
  h1 {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 12px !important;
    color: #ffffff;
  }

  p {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #04aa42;
  }
`;

const MobileSignature = styled.a`
  span {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    margin-right: 5px;
  }
`;

const CommandText = styled.p`
  font-family: "Lucida Console";
  font-style: italic;
  font-weight: 200;
  font-size: 20px;
  color: #ffffff;
`;

export default Downloads;
