// A helper component that scrolls to the top of the page
// on route change
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

@withRouter
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default ScrollToTop;
