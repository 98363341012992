import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import htmlIcon from "../../Assets/Images/html.svg";
import snowflakeIcon from "../../Assets/Images/snowflake.svg";
import bitcoin from "../../Assets/Images/bitcoin.svg";
import ethereum from "../../Assets/Images/ethereum.svg";
import "./Description.scss";

const sliderSettingsMobile = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  accessibility: true
};

const Description = props => {
  return (
    <Container
      style={{
        marginTop: window.innerWidth >= 992 ? "160px" : "63px",
        marginBottom: `${window.innerWidth >= 1000 ? "160px" : "63px"}`
      }}
    >
      <Row>
        <Col>
          <Text>
            WalletCS is a{" "}
            <span style={{ color: "#26CD58" }}>
              cold storage <br /> based
            </span>{" "}
            security platform for <br /> blockchain assets
          </Text>
        </Col>
      </Row>

      <Row>
        <Col>
          <References>
            {window.innerWidth >= 992 ? (
              <DesktopWrapper style={{ display: "flex" }}>
                <Reference>
                  <ReferenceIcons>
                    <ReferenceIcon
                      src={bitcoin}
                      alt="WalletCS Reference"
                      style={{ marginRight: "36px" }}
                    />
                    <ReferenceIcon src={ethereum} alt="WalletCS Reference" />
                  </ReferenceIcons>
                  <ReferenceTitle>Cryptocurrency Handling</ReferenceTitle>
                  <ReferenceDescription>
                    Supports BTC, ETH, ERC-20 Tokens.
                  </ReferenceDescription>
                </Reference>

                <Reference style={{ marginLeft: "19px" }}>
                  <ReferenceIcon src={htmlIcon} alt="WalletCS Reference" />
                  <ReferenceTitle>Support for smart contracts</ReferenceTitle>
                  <ReferenceDescription>
                    Customizable to accomodate custom smart contract
                    applications.
                  </ReferenceDescription>
                </Reference>

                <Reference style={{ marginLeft: "19px" }}>
                  <ReferenceIcon src={snowflakeIcon} alt="WalletCS Reference" />
                  <ReferenceTitle>Offline Key Management</ReferenceTitle>
                  <ReferenceDescription>
                    Keys are created, backed up, verified <br /> and stored
                    offline. Always.
                  </ReferenceDescription>
                </Reference>
              </DesktopWrapper>
            ) : (
              <Wrapper>
                <Slider {...sliderSettingsMobile}>
                  <Reference style={{ marginLeft: "19px" }}>
                    <ReferenceIcons>
                      <ReferenceIcon src={bitcoin} alt="WalletCS Reference" />
                      <ReferenceIcon
                        src={ethereum}
                        alt="WalletCS Reference"
                        style={{
                          paddingLeft:
                            window.innerWidth <= 992 ? "16px" : "36px"
                        }}
                      />
                    </ReferenceIcons>
                    <ReferenceTitle>Cryptocurrency Handling</ReferenceTitle>
                    <ReferenceDescription>
                      Supports BTC, ETH, ERC-20 Tokens.
                    </ReferenceDescription>
                  </Reference>

                  <Reference>
                    <ReferenceIcon src={htmlIcon} alt="WalletCS Reference" />
                    <ReferenceTitle>Support for smart contracts</ReferenceTitle>
                    <ReferenceDescription>
                      Customizable to accomodate custom smart contract
                      applications.
                    </ReferenceDescription>
                  </Reference>

                  <Reference style={{ marginLeft: "19px" }}>
                    <ReferenceIcon
                      src={snowflakeIcon}
                      alt="WalletCS Reference"
                    />
                    <ReferenceTitle>Offline Key Management</ReferenceTitle>
                    <ReferenceDescription>
                      Keys are created, backed up, verified and <br /> stored
                      offline. Always.
                    </ReferenceDescription>
                  </Reference>
                </Slider>
              </Wrapper>
            )}
          </References>
        </Col>
      </Row>
    </Container>
  );
};

export default Description;

// Styled Components
const Wrapper = styled.div`
  .slick-dots {
    display: flex !important;
  }

  .slick-dots li {
    height: 10px;
    width: 10px;
  }

  .slick-active {
    button {
      padding: 0px;
      height: 10px;
      width: 10px;

      &::before {
        height: 10px;
        width: 10px;
      }
    }
  }
`;

const DesktopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 992px) {
  }
`;

const Text = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
  z-index: 0;

  @media screen and (min-width: 1000px) {
    margin-bottom: 160px;
    font-size: 64px;
    line-height: 72px;
    margin-top: 54px;
  }
`;

const References = styled.ul`
  margin-top: 64px;
`;

const Reference = styled.div`
  display: block;
  width: 349px;

  @media screen and (max-width: 992px) {
    width: 256px;
  }
`;

const ReferenceIcon = styled.img`
  height: 24px;
  margin-bottom: 16px;
`;

const ReferenceIcons = styled.div`
  display: flex;
`;

const ReferenceTitle = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 11px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ReferenceDescription = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  width: 347px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
