import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { Link, animateScroll as scroll } from "react-scroll";
import { useSpring, animated, useTransition } from "react-spring";
import { NavLink, Redirect } from "react-router-dom";
import scrollToElement from "scroll-to-element";

import Navigation from "./Navigation";
import Download from "./Downloads/Download/Download";
import windowsIcon from "../Assets/Images/windows.svg";
import appleIcon from "../Assets/Images/apple.svg";
import linuxIcon from "../Assets/Images/linux_coloured.svg";
import downloadImage from "../Assets/Images/download_image.svg";
import closeIcon from "../Assets/Images/close.svg";
import horizontalLine from "../Assets/Images/horizontal_line.svg";
import closeMenu from "../Assets/Images/closeMenu.svg";
import githubIcon from "../Assets/Images/github.svg";
import redditIcon from "../Assets/Images/reddit.svg";
import twitterIcon from "../Assets/Images/twitter.svg";
import downArrowMobile from "../Assets/Images/down_arrow_mobile.svg";
import DownloadsMobile from "./Downloads/DownloadsMobile/DownloadsMobile";
import menu from "../Assets/Images/menu.svg";
import Icon from "./Icon";

const Header = ({ history }) => {
  // state
  const [collapse, setCollapse] = useState(false); // collapse
  const [on, toggle] = useState(false);

  // desktop navigation animation set up
  const animation = useTransition(on, null, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 344 },
    leave: { opacity: 0, height: 0 }
  });

  // mobile navigation animation set up
  const mobileAnimation = useSpring({
    zIndex: 100,
    transform: on ? "translateX(0%)" : "translateX(100%)"
  });

  // a horizontal line from dropdown downloads
  // remove if need to
  // const horizontalLineAnimation = useSpring({
  //   opacity: collapse ? 1 : 0,
  //   background: "white",
  //   width: "214px",
  //   margin: "31px auto",

  //   config: {
  //     easing: 300
  //   }
  // });

  return (
    <Fragment>
      {window.innerWidth >= 992 ? null : (
        // animation.map(
        //   ({ item, key, props }) =>
        //     item && <Downloads style={props} toggle={toggle} key={key} />
        // )
        <AnimatedMobileWrapper style={mobileAnimation}>
          <Row>
            <Col>
              <Container style={{ textAlign: "right" }}>
                <CloseIcon
                  onClick={_ => toggle(false)}
                  src={closeMenu}
                  alt="WalletCS Close"
                />
              </Container>
            </Col>
          </Row>

          <Nav style={{ marginTop: "63px" }} className="flex-column">
            <MobileLink
              className="text-center"
              to="/downloads"
              onClick={_ => toggle(false)}
            >
              Products
            </MobileLink>
            <MobileLink
              className="text-center"
              to="/downloads"
              onClick={_ => toggle(false)}
            >
              Downloads
            </MobileLink>

            {/**** UNCOMMENT IF NEED TO ****/}
            {/* <DownloadsMobile collapse={collapse} />
            {collapse ? <animated.hr style={horizontalLineAnimation} /> : null} */}

            <ExternalMobileLink
              className="text-center"
              href="https://github.com/walletcs/walletcs-app/blob/master/README.md#getting-support"
              target="__blank"
            >
              Support
            </ExternalMobileLink>

            <Link to="about" smooth>
              <MobileLink
                className="text-center"
                onClick={_ => {
                  toggle(false);

                  if (history.location.pathname === "/downloads") {
                    history.push("/");
                    scroll.scrollTo(2300);
                  }
                    
                }}
              >
                About
              </MobileLink>
            </Link>
          </Nav>

          <Row>
            <Col
              style={{
                marginTop: "33px",
                textAlign: "center"
              }}
            >
              <CreateTransaction
                href="https://app.walletcs.com"
                target="__blank"
              >
                Create Transaction
              </CreateTransaction>
            </Col>
          </Row>

          <Row
            style={{
              textAlign: "center",
              width: "160px",
              margin: "64px auto"
            }}
          >
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <SocialLink target="__blank" href="https://github.com/walletcs">
                <SocialIcon src={githubIcon} alt="Github" />
              </SocialLink>

              <SocialLink href="/">
                <SocialIcon src={redditIcon} alt="Reddit" />
              </SocialLink>

              <SocialLink href="/">
                <SocialIcon src={twitterIcon} alt="Twitter" />
              </SocialLink>
            </Col>
          </Row>

          <Row>
            <Col style={{ textAlign: "center" }}>
              <TermsOfUse
                href="https://github.com/walletcs/walletcs-app/blob/master/LICENSE"
                target="__blank"
              >
                Terms of use
              </TermsOfUse>
            </Col>
          </Row>
        </AnimatedMobileWrapper>
      )}

      <Container
        style={{
          marginTop: "16px"
        }}
      >
        <Wrapper>
          <Row>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%"
              }}
            >
              <Logo to="/">
                <Icon type="logo" />
              </Logo>
            </Col>

            {window.innerWidth >= 992 ? (
              <Col>
                <Navbar
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <MenuLink
                    style={{ color: "#9198a0" }}
                    activeStyle={{ color: "#ffffff" }}
                    to="/downloads"
                  >
                    Products
                  </MenuLink>
                  <MenuLink
                    style={{ color: "#9198a0" }}
                    activeStyle={{ color: "#ffffff" }}
                    to="/downloads"
                  >
                    Downloads
                  </MenuLink>

                  <MenuLink
                    style={{ color: "#9198a0" }}
                    onClick={_ => {
                      if (history.location.pathname === "/downloads") {
                        history.push("/");
                        scroll.scrollTo(2200);
                      }
                      scrollToElement(".about");
                    }}
                  >
                    About
                  </MenuLink>
                  <ExternalMenuLink
                    href="https://github.com/walletcs/walletcs-app/blob/master/README.md#getting-support"
                    target="__blank"
                    style={{ color: "#9198a0" }}
                  >
                    Support
                  </ExternalMenuLink>
                  <CreateTransactionButton
                    href="https://app.walletcs.com/"
                    target="__blank"
                  >
                    Create Transaction
                  </CreateTransactionButton>
                </Navbar>
              </Col>
            ) : (
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <MenuIcon
                  onClick={_ => toggle(true)}
                  src={menu}
                  alt="WalletCS Menu Icon"
                />
                )}
              </Col>
            )}
          </Row>
        </Wrapper>
      </Container>
    </Fragment>
  );
};

export default Header;

// Styled Components
const Wrapper = styled.div`
  height: 100%;
`;

const Logo = styled(NavLink)`
  cursor: pointer;
  padding-top: 10px;
  outline: 0;
`;

const MenuIcon = styled.img`
  cursor: pointer;
`;

const MenuLink = styled(NavLink)`
  font-family: "IBM Plex Sans" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-left: 28px;
  cursor: pointer;
  position: relative;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
  outline: 0;

  &:-moz-focus-inner {
    border: 0;
  }

  &:hover {
    color: #04aa42 !important;
    text-decoration: none;

    .downloads_arrow {
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
      fill: #04aa42 !important;
    }
  }

  &:active {
    color: #039138 !important;

    .downloads_arrow {
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
      fill: #039138 !important;
    }
  }
`;

const ExternalMenuLink = styled.a`
  font-family: "IBM Plex Sans" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-left: 28px;
  cursor: pointer;
  position: relative;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
  outline: 0;

  &:-moz-focus-inner {
    border: 0;
  }

  &:hover {
    color: #04aa42 !important;
    text-decoration: none;

    .downloads_arrow {
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
      fill: #04aa42 !important;
    }
  }

  &:active {
    color: #039138 !important;

    .downloads_arrow {
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
      fill: #039138 !important;
    }
  }
`;

const CreateTransactionButton = styled.a`
  background: #04aa42;
  border-radius: 2px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  width: 157px;
  height: 44px;
  margin-left: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #ffffff !important;

  &:hover {
    background: #05c44c;
    border-radius: 4px;
    text-decoration: none;
    color: #ffffff;
  }

  &:active {
    background: #039138;
    border-radius: 4px;
  }
`;

// Downloads Styled Components
const DownloadWrapper = styled.div`
  background: #ffffff;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const DownloadImage = styled.img`
  @media screen and (max-width: 1200px) {
    height: 50px;
  }

  @media screen and (max-width: 992px) {
    height: 40px;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 107px;

  @media screen and (max-width: 1200px) {
    margin-left: 77px;
  }

  @media screen and (max-width: 1188px) {
    margin-left: 57px;
  }

  @media screen and (max-width: 1170px) {
    margin-left: 47px;
  }

  @media screen and (max-width: 1120px) {
    margin-left: 27px;
  }

  @media screen and (max-width: 1099px) {
    margin-left: 17px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 7px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: -17px;
  }

  @media screen and (max-width: 100px) {
    margin-left: -27px;
  }
`;

const CloseButton = styled.img`
  margin-left: 88px;
  margin-right: 200px;
  cursor: pointer;

  &:hover {
    fill: #26cd58 !important;
  }
`;

const HR = styled.img`
  margin-left: 107px;

  @media screen and (max-width: 1200px) {
    margin-left: 77px;
  }

  @media screen and (max-width: 1188px) {
    margin-left: 57px;
  }

  @media screen and (max-width: 1170px) {
    margin-left: 47px;
  }

  @media screen and (max-width: 1120px) {
    margin-left: 57px;
  }

  @media screen and (max-width: 1099px) {
    margin-left: 47px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 37px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 37px;
  }
`;

// Mobile styled components
const MobileWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  background: #010d17;
  z-index: 10;
  display: block;
  width: 100%;
`;

const AnimatedMobileWrapper = animated(MobileWrapper);

const CloseIcon = styled.img`
  cursor: pointer;
  margin-top: 20px;
`;

const MobileLink = styled(NavLink)`
  text-align: right;
  display: block;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 32px;
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
  z-index: 2;
  position: relative;
  outline: 0;

  &:hover {
    color: white !important;
    text-decoration: none;
  }
`;

const ExternalMobileLink = styled.a`
  text-align: right;
  display: block;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 32px;
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
  z-index: 2;
  position: relative;
  outline: 0;

  &:hover {
    color: white !important;
    text-decoration: none;
  }
`;

// const DownloadButton = styled.a`
//   background: rgba(38, 205, 88, 0.241423);
//   border: 1px solid #04aa42;
//   box-sizing: border-box;
//   border-radius: 2px;
//   padding: 13px 16px;
//   cursor: pointer;
//   font-family: "IBM Plex Sans";
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   margin-right: 16px;
//   transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

//   &:hover {
//     background: #05c44c;
//     border-radius: 4px;
//   }

//   &:active {
//     background: #039138;
//     border-radius: 4px;
//   }
// `;

const CreateTransaction = styled.a`
  background: #04aa42;
  border-radius: 2px;
  padding: 13px 16px;
  cursor: pointer;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #ffffff;

  &:hover {
    background: #05c44c;
    border-radius: 4px;
    text-decoration: none;
    color: #ffffff;
  }

  &:active {
    background: #039138;
    border-radius: 4px;
  }
`;

const SocialLink = styled.a``;

const SocialIcon = styled.img``;

const TermsOfUse = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  text-align: center;
`;
