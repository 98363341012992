import React from "react";
import { Switch, Route } from "react-router-dom";

import { Layout } from "./Components";
import { MainPage, Downloads } from "./Pages";

const Routes = props => {
  return (
    <Layout>
      <Switch>
        <Route
          exact
          path="/"
          render={({ history }) => <MainPage history={history} />}
        />

        <Route
          path="/downloads"
          render={({ history }) => <Downloads history={history} />}
        />
      </Switch>
    </Layout>
  );
};

export default Routes;
