import React, { Fragment } from "react";
import { animated } from "react-spring";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

import Download from "./Download/Download";
import windowsIcon from "../../Assets/Images/windows.svg";
import appleIcon from "../../Assets/Images/apple.svg";
import linuxIcon from "../../Assets/Images/linux_coloured.svg";
import downloadImage from "../../Assets/Images/download_image.svg";
import closeIcon from "../../Assets/Images/close.svg";
import horizontalLine from "../../Assets/Images/horizontal_line.svg";

import {
  LINUX_X64_FILENAME,
  LINUX_X32_FILENAME,
  ARMV7_FILENAME,
  LINUX_X64_FILESIZE,
  LINUX_X32_FILESIZE,
  ARMV7_FILESIZE
} from "../../Utils/constants";

export default ({ toggle, style, key }) => (
  <Fragment key={key}>
    <Background onClick={_ => toggle(false)} />
    <Wrapper style={style}>
      <Container>
        <Row style={{ paddingTop: "66px" }}>
          <Col xl={4} lg={4} md={5}>
            <div style={{ display: "flex" }}>
              <DownloadImage src={downloadImage} alt="WalletCS Download" />
              <HR src={horizontalLine} alt="WalletCS Line" />
            </div>
          </Col>

          <Col xl={8} lg={8} md={7}>
            <List>
              {/* <Download icon={windowsIcon} os="Windows" size="34.4" /> */}
              <Download icon={appleIcon} os="MacOS" size="34.4" />

              <ul>
                <Download
                  icon={linuxIcon}
                  os="Linux 64 bit"
                  size="72.5"
                  downloadLink="https://s3.amazonaws.com/downloads.walletcs.com/walletcs-1.0.11-x86_64.AppImage"
                  link={LINUX_X64_FILENAME}
                  size={LINUX_X64_FILESIZE}
                />
                <Download
                  hasIcon={false}
                  os="32 bit"
                  link={LINUX_X32_FILENAME}
                  size={LINUX_X32_FILESIZE}
                />
                <Download
                  hasIcon={false}
                  os="ARM"
                  link={ARMV7_FILENAME}
                  size={ARMV7_FILESIZE}
                />
              </ul>

              <CloseButton
                src={closeIcon}
                alt="WalletCS Close"
                onClick={_ => toggle(false)}
              />
            </List>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  </Fragment>
);

// Styled Components
const Wrapper = styled(animated.div)`
  background: white;
`;

const Background = styled.div`
  position: fixed;
  top: 344px;
  left: 0;
  height: 100%;
  width: 100%;
`;

const DownloadImage = styled.img`
  @media screen and (max-width: 1200px) {
    height: 50px;
  }

  @media screen and (max-width: 992px) {
    height: 40px;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 107px;

  @media screen and (max-width: 1200px) {
    margin-left: 77px;
  }

  @media screen and (max-width: 1188px) {
    margin-left: 57px;
  }

  @media screen and (max-width: 1170px) {
    margin-left: 47px;
  }

  @media screen and (max-width: 1120px) {
    margin-left: 27px;
  }

  @media screen and (max-width: 1099px) {
    margin-left: 17px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 7px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: -17px;
  }

  @media screen and (max-width: 100px) {
    margin-left: -27px;
  }
`;

const CloseButton = styled.img`
  margin-left: 88px;
  margin-right: 200px;
  cursor: pointer;

  &:hover {
    fill: #26cd58 !important;
  }
`;

const HR = styled.img`
  margin-left: 107px;

  @media screen and (max-width: 1200px) {
    margin-left: 77px;
  }

  @media screen and (max-width: 1188px) {
    margin-left: 57px;
  }

  @media screen and (max-width: 1170px) {
    margin-left: 47px;
  }

  @media screen and (max-width: 1120px) {
    margin-left: 57px;
  }

  @media screen and (max-width: 1099px) {
    margin-left: 47px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 37px;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 37px;
  }
`;
