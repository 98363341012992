import React, { Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

import Icon from "./Icon";

export default props => (
  <Container style={{ marginTop: "160px" }}>
    <Row>
      <Col>
        {window.innerWidth >= 992 ? (
          // Desktop Social
          <Icons>
            <IconWrapper
              target="__blank"
              href="https://github.com/walletcs"
              iconType="github"
            >
              <Icon type="github" />
              <IconTitle className="icon_title" iconType="github">
                Github
              </IconTitle>
            </IconWrapper>

            <IconWrapper target="__blank" href="" iconType="reddit">
              <Icon type="reddit" />
              <IconTitle className="icon_title" iconType="reddit">
                Reddit
              </IconTitle>
            </IconWrapper>

            <IconWrapper target="__blank" href="" iconType="twitter">
              <Icon type="twitter" />
              <IconTitle className="icon_title" iconType="twitter">
                Twitter
              </IconTitle>
            </IconWrapper>
          </Icons>
        ) : (
          // Mobile Social
          <Fragment>
            <Icons>
              <LinkMobile target="__blank" href="https://github.com/walletcs">
                <Icon type="github" />
              </LinkMobile>

              <LinkMobile target="__blank" href="">
                <Icon type="reddit" isMobile />
              </LinkMobile>

              <LinkMobile target="__blank" href="'">
                <Icon type="twitter" isMobile />
              </LinkMobile>
            </Icons>

            {window.innerWidth <= 768 ? (
              <Terms
                href="https://github.com/walletcs/walletcs-app/blob/master/LICENSE"
                target="__blank"
              >
                Terms Of use
              </Terms>
            ) : null}
          </Fragment>
        )}
      </Col>
    </Row>
  </Container>
);

// Styled Components
const Icons = styled.ul`
  display: flex;

  @media screen and (min-width: 992px) {
    margin-left: -20px;
  }

  @media screen and (max-width: 992px) {
    margin-top: -70px !important;
  }
`;

const IconWrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 78px;
  width: 106px;

  &:hover {
    text-decoration: none;

    .icon_title {
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

      color: ${({ iconType }) =>
        iconType === "github"
          ? "#6e7782"
          : iconType === "reddit"
          ? "#F95721"
          : iconType === "twitter"
          ? "#21CAFF"
          : ""};
    }

    .social_icon {
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

      fill: ${({ iconType }) =>
        iconType === "github"
          ? "#6e7782"
          : iconType === "reddit"
          ? "#F95721"
          : iconType === "twitter"
          ? "#21CAFF"
          : ""};
    }
  }
`;

const IconTitle = styled.span`
  margin-left: 16px;
  font-family: "IBM Plex Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;

  &:active {
    fill: ${({ iconType }) =>
      iconType === "github"
        ? "#99A6B5"
        : iconType === "reddit"
        ? "#FF4000"
        : iconType === "twitter"
        ? "#54D6FF"
        : ""};
  }
`;

const LinkMobile = styled.a``;

const Terms = styled.a`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  display: block;
  margin-top: 61px;

  &:hover {
    color: white !important;
    text-decoration: none;
  }

  &:active {
    color: white !important;
  }
`;
