import React from "react";
import styled from "styled-components";
import { Collapse } from "reactstrap";

import Download from "../Download/Download";
import windowsOS from "../../../Assets/Images/windows.svg";
import macOS from "../../../Assets/Images/apple_white.svg";
import linuxOS from "../../../Assets/Images/linux_coloured.svg";

export default ({ collapse, isOutside = false }) => (
  <Collapse isOpen={collapse}>
    <CollapseWrapper isOutside={isOutside}>
      <Download color="#ffffff" icon={windowsOS} os="Windows" size="34.4" />
      <Download color="#ffffff" icon={macOS} os="Mac" size="34.4" />
      <Download color="#ffffff" icon={linuxOS} os="Linux" size="34.4" />
      <Download hasIcon={false} os="32 Bit" size="34.4" />
      <Download hasIcon={false} os="ARM" size="34.4" />
    </CollapseWrapper>
  </Collapse>
);

// Styled Components
const CollapseWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff !important;
  margin-top: ${({ isOutside }) => (isOutside ? "40px" : "0px")};
`;
