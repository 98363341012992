import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-scroll";
import styled from "styled-components";
import { useTransition } from "react-spring";
import { NavLink } from "react-router-dom";

import Downloads from "./Downloads/Downloads";
import Toggle from "../Utils/Toggle";
import logo from "../Assets/Images/logo.svg";

const Footer = props => {
  // hooks
  const [on, toggle] = useState(false); // toggle state

  // animations
  const navigationAnimation = useTransition(on, null, {
    from: { opacity: 0, height: 0 },
    enter: {
      opacity: 1,
      height: 344,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 1
    },
    leave: { opacity: 0, height: 0 }
  });

  return (
    <Fragment>
      {window.innerWidth >= 992 &&
        navigationAnimation.map(
          ({ item, key, props }) =>
            item && <Downloads toggle={toggle} style={props} key={key} />
        )}
      <Container
        style={{
          marginTop: `${window.innerWidth >= 1000 ? "180px" : "69px"}`,
          marginBottom: `${window.innerWidth >= 1000 ? "71px" : "23px"}`
        }}
      >
        <Row>
          {window.innerWidth >= 992 ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <Col lg={5}>
                <Logo src={logo} alt="WalletCS Logo" />

                <div>
                  <Title>© 2019 WalletCS.</Title>
                </div>
              </Col>

              <Col>
                <Links>
                  <div>
                    <ExternalMenuLink
                      href="https://github.com/walletcs/walletcs-app/blob/master/README.md#walletcs"
                      target="_blank"
                    >
                      Documentation
                    </ExternalMenuLink>
                    <MenuLink
                      to="/downloads"
                      activeStyle={{ color: "#ffffff !important" }}
                    >
                      Downloads
                    </MenuLink>
                    <ExternalMenuLink
                      href="https://github.com/walletcs/walletcs-app/blob/master/README.md#FAQ"
                      target="__blank"
                    >
                      FAQ
                    </ExternalMenuLink>
                  </div>

                  <div>
                    <ExternalMenuLink
                      href="https://github.com/walletcs/walletcs-app/blob/master/README.md#getting-support"
                      target="__blank"
                    >
                      Support
                    </ExternalMenuLink>

                    <Link to="about" style={{ textDecoration: "none" }} smooth>
                      <MenuLink>About</MenuLink>
                    </Link>

                    <ExternalMenuLink
                      href="https://github.com/walletcs/walletcs-app/blob/master/LICENSE"
                      target="__blank"
                    >
                      Terms of use
                    </ExternalMenuLink>
                  </div>
                </Links>
              </Col>
            </div>
          ) : (
            <Col>
              <Title>© 2019 WalletCS.</Title>
            </Col>
          )}
        </Row>
      </Container>
      )}
    </Fragment>
  );
};

export default Footer;

// Styled Components
const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6e7782;
  display: inline-block;
`;

const Logo = styled.img`
  margin-bottom: 95px;
`;

// const Terms = styled.a`
//   font-family: "IBM Plex Sans";
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6e7782 !important;
//   margin-left: 23px;

//   outline: 0;

//   &---moz-focus-inner {
//     border: 0;
//   }
// `;

const Links = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 436px;
`;

const ExternalMenuLink = styled.a`
font-family: "IBM Plex Sans";
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 56px;
color: #ffffff !important;
text-decoration: none;
display: block;
cursor: pointer;
margin-bottom: 10px;
position: relative;
outline: 0;
transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

&:-moz-focus-inner {
  border: 0;
}

&:hover {
  color: #04aa42 !important;
  text-decoration: none;
}

&:active {
  color: #039138 !important;
}
`;


const MenuLink = styled(NavLink)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 56px;
  color: #ffffff !important;
  text-decoration: none;
  display: block;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  outline: 0;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:-moz-focus-inner {
    border: 0;
  }

  &:hover {
    color: #04aa42 !important;
    text-decoration: none;
  }

  &:active {
    color: #039138 !important;
  }
`;
