import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useTransition, animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";
import { NavLink } from "react-router-dom";

import Downloads from "../Downloads/Downloads";
import DownloadsMobile from "../Downloads/DownloadsMobile/DownloadsMobile";

const Why = ({ toggle, on }) => {
  // hooks
  // const [on, toggle] = useState(false); // toggle
  const [collapse, toggleCollapse] = useState(false); // collapse
  const [spotted, setSpotted] = useState(false); // waypoints trigger state

  // animations
  const textAnimation = useSpring({
    opacity: spotted ? 1 : 0
  });

  const navigationAnimation = useTransition(on, null, {
    from: { opacity: 0, height: 0 },
    enter: {
      opacity: 1,
      height: 344,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 1
    },
    leave: { opacity: 0, height: 0 }
  });

  return (
    <Fragment>
      <Container>
        <Row>
          {window.innerWidth <= 992 ? (
            <Col style={{ marginTop: "61px" }}>
              <Title>Why WalletCS is secure</Title>
              <Description style={{ fontWeight: "bold" }}>
                <span style={{ color: "#26CD58" }}>Process offered by</span>{" "}
                WalletCS{" "}
                <span style={{ color: "#26CD58" }}>
                  ensures that your private keys are generated and handled in
                  the
                </span>{" "}
                offline, "air-gapped" environment{" "}
                <span style={{ color: "#26CD58" }}>
                  which is never connected to the internet.
                  <br />
                  <br />
                  As long as you ensure private keys storage physical security
                  and follow the recommended procedures,
                </span>{" "}
                your private keys are safe.
                <br />
                <br />
                <SmallDescription>
                  Most blockchain assets, such as coins, tokens or smart
                  contracts are controlled by security credentials, known as key
                  pairs, which consists of private and public keys. To transfer
                  coins, tokens or send a command to a smart contract, you have
                  to use a private key associated with it. The only way the
                  attacker can gain control over your blockchain assets is to
                  get hold of your private keys.
                </SmallDescription>
              </Description>

              <DownloadButtonMobile to="/downloads">
                Download
              </DownloadButtonMobile>
              <DownloadsMobile collapse={collapse} isOutside />
            </Col>
          ) : (
            <Fragment>
              <Col xl={5} lg={5}>
                <Title>
                  Why WalletCS <br /> is secure
                </Title>
                <Description>
                  Most blockchain assets, such as coins, tokens or smart
                  contracts are controlled by security credentials, known as key
                  pairs, which consists of private and public keys. To transfer
                  coins, tokens or send a command to a smart contract, you have
                  to use a private key associated with it.
                  <br />
                  <br />
                  The only way the attacker can gain control over your
                  blockchain assets is to get hold of your private keys.
                </Description>

                <br />

                <DownloadButton to="/downloads">Download</DownloadButton>
              </Col>

              <Col>
                <Waypoint
                  onEnter={_ => setSpotted(true)}
                  bottomOffset="30%"
                  onLeave={_ => setSpotted(false)}
                />
                <BigDescription>
                  <span className={spotted ? "tile tile-1" : "tile tileOut-1"}>
                    <span style={{ color: "#26CD58" }}>Process offered by</span>{" "}
                    WalletCS{" "}
                    <span style={{ color: "#26CD58" }}>ensures that</span>
                  </span>{" "}
                  <span className={spotted ? "tile tile-2" : "tile tileOut-2"}>
                    your private keys are generated and
                  </span>{" "}
                  <span className={spotted ? "tile tile-3" : "tile tileOut-3"}>
                    handled in the offline, "air-gapped"
                  </span>
                  <span className={spotted ? "tile tile-4" : "tile tileOut-4"}>
                    environment which is never connected to
                  </span>{" "}
                  <span style={{ color: "#26CD58" }}>
                    <span
                      className={spotted ? "tile tile-5" : "tile tileOut-5"}
                    >
                      the internet.
                    </span>
                    <br />
                    <span
                      className={spotted ? "tile tile-6" : "tile tileOut-6"}
                    >
                      As long as you ensure private keys storage physical
                      security
                    </span>

                    <span
                      className={spotted ? "tile tile-7" : "tile tileOut-7"}
                    >
                      and follow the recommended procedures,
                    </span>
                  </span>{" "}
                  <span className={spotted ? "tile tile-8" : "tile tileOut-8"}>
                    your private keys are safe.
                  </span>
                </BigDescription>
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Why;

// export default class Why extends Component {
//   state = { collapse: false };

//   toggleCollapse = _ =>
//     this.setState(({ collapse }) => ({ collapse: !collapse }));
// }

// Styled Components
const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;

  color: #ffffff;
  margin-bottom: 38px;

  @media screen and (max-width: 769px) {
    font-size: 24px !important;
    line-height: 32px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 44px;
    line-height: 56px;
  }

  @media screen and (max-width: 992px) {
    font-size: 36px !important;
    line-height: 44px;
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;

  @media screen and (min-width: 992px) {
    width: 346px;
  }

  @media screen and (max-width: 769px) {
    font-size: 18px !important;
    line-height: 28px;
  }
`;

const BigDescription = styled(animated.p)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: white;

  @media screen and (max-width: 769px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const DownloadButtonMobile = styled(NavLink)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #26cd58 !important;
  position: relative;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg, #027722 0%, #26cd58 0.01%);
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    bottom: -6px;
  }

  &:hover {
    color: white !important;
  }

  &:active:before {
    background: white;
  }
`;

const DownloadButton = styled(NavLink)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #26cd58 !important;
  position: relative;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg, #027722 0%, #26cd58 0.01%);
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    bottom: -6px;
  }

  &:hover {
    color: white !important;
  }

  &:active:before {
    background: white;
  }
`;

const SmallDescription = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
`;
